var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"id":"container"}},[_c('Header'),_c('v-card',{attrs:{"id":"main_card"}},[_c('v-card-title',[_vm._v("My Bookings")]),_vm._l((_vm.bookings),function(booking){return _c('v-container',{key:booking.id,attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{attrs:{"flat":"","color":"grey lighten-4","ripple":""},on:{"click":function($event){return _vm.$router.push(`/booking/${booking.uuid}`)}}},[_c('v-card-title',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('span',[_vm._v("#"+_vm._s(booking.refNo))]),[(booking.isPaid)?_c('v-chip',{attrs:{"color":booking.isPaidStripe ? '#F79E1B' : 'green',"title":booking.isPaidStripe
                  ? 'Paid in USD though Stripe payment gateway'
                  : undefined}},[_vm._v("Paid "),(booking.isPaidStripe)?_c('v-icon',{staticStyle:{"margin-left":"0.3rem"}},[_vm._v("mdi-credit-card-outline")]):_vm._e()],1):_vm._e(),(booking.isAllowed)?_c('v-chip',{attrs:{"color":"cyan"}},[_vm._v("Allowed"+_vm._s(booking.allowedType ? ` (${booking.allowedType})` : ""))]):_vm._e(),(
                booking.isCashAllowed && !booking.isPaid && !booking.isAllowed
              )?_c('v-chip',{attrs:{"color":"pink lighten-3"}},[_vm._v("Cash On Arrival")]):_vm._e(),(
                !booking.isPaid &&
                !booking.isAllowed &&
                !booking.isCashAllowed
              )?_c('div',[(
                  booking.transfer.id !== 0 &&
                  booking.transfer.status === 'Pending'
                )?_c('v-chip',{attrs:{"color":"orange"}},[_vm._v("Pending Verification")]):(
                  booking.cashPayment.id !== 0 &&
                  booking.cashPayment.status === 'Pending'
                )?_c('v-chip',{attrs:{"color":"orange"}},[_vm._v("Pending Cash Option")]):(booking.isCancelled)?_c('v-chip',{attrs:{"color":"white"}},[_vm._v("Cancelled")]):_c('v-chip',[_vm._v("Pending")])],1):_vm._e()]],2),_c('v-card-text',{staticClass:"bg-surface-light pt-4"},[_vm._v(_vm._s(_vm.toFromString(booking.trip)))])],1)],1)}),(!_vm.bookings.length)?_c('v-container',[_vm._v("Nothing to show here...")]):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }