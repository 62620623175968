<template>
  <div style="padding-bottom: 20px">
    <div v-if="error">{{ error }}</div>
    <div v-else style="max-width: 600px; margin: auto">
      <div v-if="trip">
        <v-card-title> Trip #{{ tripId }} Payments </v-card-title>
        <v-card-subtitle class="text-subtitle-1">
          <span style="margin-right: 10px">{{ toFromString(trip) }}</span>
          <span style="padding-top: 3px">{{
            getDatePortion(trip.fromTime)
          }}</span>
          <div>
            <v-icon class="mb-1">mdi-ferry</v-icon>
            <span class="subtitle-1">{{ trip.boat.name }}</span>
          </div>
          <v-icon dense class="mb-1">mdi-map-marker</v-icon>
          <span class="subtitle-1">{{ trip.fromLocation.name }}</span>
          <v-icon class="mb-1">mdi-ray-start-arrow</v-icon>
          <span class="subtitle-1">{{ trip.toLocation.name }}</span>
          <FuelConsumption
            v-if="trip.fuelConsumptionLitres"
            :trip="trip"
            :hideButton="true"
          />
        </v-card-subtitle>
      </div>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-simple-table v-if="report" style="white-space: nowrap">
        <template v-slot:default>
          <tbody>
            <tr>
              <td style="width: 100%">
                <div style="padding-top: 0.5rem">Free of charge</div>
                <TripPaymentBookings
                  :bookings="filterBookings('', 'Free of charge')"
                />
              </td>
              <td></td>
            </tr>
            <tr
              v-if="
                bmlGatewayAllowed ||
                report.mvrtransaction > 0 ||
                report.usdtransaction > 0
              "
            >
              <td style="width: 100%">
                <div style="padding-top: 0.5rem">Card</div>
                <TripPaymentBookings
                  :bookings="filterBookings('MVR', 'Card')"
                />
              </td>
              <td style="text-align: right">
                {{ decimalCurrency(report.mvrtransaction) }} MVR
              </td>
            </tr>
            <tr
              v-if="
                bmlGatewayAllowed ||
                report.mvrtransaction > 0 ||
                report.usdtransaction > 0
              "
            >
              <td style="width: 100%">
                <div style="padding-top: 0.5rem">Card</div>
                <TripPaymentBookings
                  :bookings="filterBookings('USD', 'Card')"
                />
              </td>
              <td style="text-align: right">
                {{ decimalCurrency(report.usdtransaction) }} USD
              </td>
            </tr>
            <tr>
              <td style="width: 100%">
                <div style="padding-top: 0.5rem">Transfer</div>
                <TripPaymentBookings
                  :bookings="filterBookings('MVR', 'Transfer')"
                />
              </td>
              <td style="text-align: right">
                {{ decimalCurrency(report.mvrtransfer) }} MVR
              </td>
            </tr>
            <tr>
              <td style="width: 100%">
                <div style="padding-top: 0.5rem">Transfer</div>
                <TripPaymentBookings
                  :bookings="filterBookings('USD', 'Transfer')"
                />
              </td>
              <td style="text-align: right">
                {{ decimalCurrency(report.usdtransfer) }} USD
              </td>
            </tr>
            <tr>
              <td style="width: 100%">
                <div style="padding-top: 0.5rem">Cash</div>
                <TripPaymentBookings
                  :bookings="filterBookings('MVR', 'Cash')"
                />
              </td>
              <td style="text-align: right">
                {{ decimalCurrency(report.mvrcash) }} MVR
              </td>
            </tr>
            <tr>
              <td style="width: 100%">
                <div style="padding-top: 0.5rem">Cash</div>
                <TripPaymentBookings
                  :bookings="filterBookings('USD', 'Cash')"
                />
              </td>
              <td style="text-align: right">
                {{ decimalCurrency(report.usdcash) }} USD
              </td>
            </tr>
            <tr>
              <td style="width: 100%">
                <div style="padding-top: 0.5rem">Credit</div>
                <TripPaymentBookings
                  :bookings="filterBookings('MVR', 'Credit')"
                />
              </td>
              <td style="text-align: right">
                {{ decimalCurrency(report.mvrcredit) }} MVR
              </td>
            </tr>
            <tr>
              <td style="width: 100%">
                <div style="padding-top: 0.5rem">Credit</div>
                <TripPaymentBookings
                  :bookings="filterBookings('USD', 'Credit')"
                />
              </td>
              <td style="text-align: right">
                {{ decimalCurrency(report.usdcredit) }} USD
              </td>
            </tr>
            <tr>
              <td style="width: 100%">
                <div style="padding-top: 0.5rem">Pre-paid</div>
                <TripPaymentBookings
                  :bookings="filterBookings('MVR', 'Pre-paid')"
                />
              </td>
              <td style="text-align: right">
                {{ decimalCurrency(report.mvrprepaid) }} MVR
              </td>
            </tr>
            <tr>
              <td style="width: 100%">
                <div style="padding-top: 0.5rem">Pre-paid</div>
                <TripPaymentBookings
                  :bookings="filterBookings('USD', 'Pre-paid')"
                />
              </td>
              <td style="text-align: right">
                {{ decimalCurrency(report.usdprepaid) }} USD
              </td>
            </tr>
            <tr class="font-weight-bold">
              <td style="width: 100%">Total</td>
              <td style="text-align: right">
                <div>{{ decimalCurrency(report.mvrtotal) }} MVR</div>
                <div>{{ decimalCurrency(report.usdtotal) }} USD</div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import FuelConsumption from "../components/Trip/FuelConsumption.vue";
import TripPaymentBookings from "../components/Trip/TripPaymentBookings.vue";
import {
  apiRequest,
  decimalCurrency,
  filterBookings,
  getDatePortion,
  toFromString,
} from "../helpers";

export default {
  name: "TripPayments",
  props: {
    tripId: String,
  },
  components: { TripPaymentBookings },
  data: () => ({
    dialog: null,
    report: null,
    loading: false,
    bookings: [],
    bookingsLoading: false,
    error: "",
    trip: null,
    tripLoading: false,
  }),
  computed: {
    bmlGatewayAllowed() {
      return process.env.VUE_APP_ALLOW_BML_GATEWAY === "true" ? true : false;
    },
  },
  methods: {
    filterBookings(currency, type) {
      return filterBookings(currency, this.bookings, type, true);
    },
    toFromString(trip) {
      return toFromString(trip, this.$store.getters.getConfigs);
    },
    getDatePortion(x) {
      return getDatePortion(x);
    },
    decimalCurrency(number) {
      return decimalCurrency(number);
    },
    getTrip() {
      const endpoint = `/api/gettrip?id=${this.tripId}`;
      const thenHandle = (resp) => {
        this.trip = resp.data.trip ?? null;
      };
      const catchHandle = (error) => {
        if (error.response) {
          this.error = error.response.data.error;
        }
      };
      const finallyHandle = () => {
        this.bookingsLoading = false;
      };
      this.bookingsLoading = true;
      apiRequest({
        endpoint,
        methods: "GET",
        thenHandle,
        catchHandle,
        finallyHandle,
      });
    },
    getBookings() {
      const endpoint = `/api/getbookingsfortrip?tripId=${this.tripId}`;
      const thenHandle = (resp) => {
        const bookings = resp.data.bookings ?? [];
        this.bookings = bookings;
        this.getPaymentDetails();
      };
      const catchHandle = (error) => {
        if (error.response) {
          this.error = error.response.data.error;
          if (error.response.data.error === "Trip does not exist.") {
            return;
          } else {
            this.error += " Returning to login page.";
            localStorage.setItem("prevRoute", `/trip-payments/${this.tripId}`);
            setTimeout(function () {
              window.location.pathname = "/login";
            }, 5000);
          }
        }
      };
      const finallyHandle = () => {
        this.bookingsLoading = false;
      };
      this.bookingsLoading = true;
      apiRequest({
        endpoint,
        method: "GET",
        thenHandle,
        catchHandle,
        finallyHandle,
      });
    },
    getPaymentDetails() {
      this.loading = true;
      const query = `?tripId=${this.tripId}`;
      axios({
        url: `${
          process.env.VUE_APP_BASE_URL ?? ""
        }/api/trippaymentreport${query}`,
        method: "GET",
        headers: { Authorization: localStorage.getItem("Authorization") },
      })
        .then((resp) => {
          this.report = resp.data.report;
        })
        .catch((error) => {
          if (error.response) {
            this.error = error.response.data.error;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getConfigs() {
      const endpoint = "/api/getconfigs";
      const thenHandle = (resp) => {
        const configs = resp?.data?.configs;
        if (configs) {
          this.configs = configs;
          this.$store.dispatch("configure", {
            configs,
          });
        }
      };
      apiRequest({
        endpoint,
        method: "GET",
        thenHandle,
      });
    },
  },
  mounted() {
    document.title = `Trip #${this.tripId} Payments`;
    this.getConfigs();
    this.getTrip();
    this.getBookings();
  },
  components: { TripPaymentBookings, FuelConsumption },
};
</script>
