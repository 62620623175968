<template>
  <v-container id="container">
    <Header />
    <v-card id="main_card">
      <v-card-title>My Bookings</v-card-title>
      <v-container
        v-for="booking in bookings"
        :key="booking.id"
        cols="12"
        sm="6"
        md="4"
      >
        <v-card
          flat
          color="grey lighten-4"
          ripple
          @click="$router.push(`/booking/${booking.uuid}`)"
        >
          <v-card-title style="display: flex; justify-content: space-between"
            ><span>#{{ booking.refNo }}</span>
            <template class="shrink" style="padding-left: 0px">
              <v-chip
                v-if="booking.isPaid"
                :color="booking.isPaidStripe ? '#F79E1B' : 'green'"
                :title="
                  booking.isPaidStripe
                    ? 'Paid in USD though Stripe payment gateway'
                    : undefined
                "
                >Paid
                <v-icon v-if="booking.isPaidStripe" style="margin-left: 0.3rem"
                  >mdi-credit-card-outline</v-icon
                ></v-chip
              >
              <v-chip v-if="booking.isAllowed" color="cyan"
                >Allowed{{
                  booking.allowedType
                    ? `
                              (${booking.allowedType})`
                    : ""
                }}</v-chip
              >
              <v-chip
                v-if="
                  booking.isCashAllowed && !booking.isPaid && !booking.isAllowed
                "
                color="pink lighten-3"
                >Cash On Arrival</v-chip
              >
              <div
                v-if="
                  !booking.isPaid &&
                  !booking.isAllowed &&
                  !booking.isCashAllowed
                "
              >
                <v-chip
                  v-if="
                    booking.transfer.id !== 0 &&
                    booking.transfer.status === 'Pending'
                  "
                  color="orange"
                  >Pending Verification</v-chip
                >
                <v-chip
                  v-else-if="
                    booking.cashPayment.id !== 0 &&
                    booking.cashPayment.status === 'Pending'
                  "
                  color="orange"
                  >Pending Cash Option</v-chip
                >
                <v-chip v-else-if="booking.isCancelled" color="white"
                  >Cancelled</v-chip
                >
                <v-chip v-else>Pending</v-chip>
              </div>
            </template></v-card-title
          >
          <v-card-text class="bg-surface-light pt-4">{{
            toFromString(booking.trip)
          }}</v-card-text>
        </v-card>
      </v-container>
      <v-container v-if="!bookings.length">Nothing to show here...</v-container>
    </v-card>
  </v-container>
</template>
<script>
import BookingDialog from "../components/BookingDialog.vue";
import Header from "../components/Header.vue";
import { apiRequest, toFromString } from "../helpers";
export default {
  name: "Bookings",
  data() {
    return {
      bookings: [],
    };
  },
  methods: {
    toFromString(trip) {
      return toFromString(trip, this.$store.getters.getConfigs);
    },
  },
  components: {
    BookingDialog,
    Header,
  },
  async created() {
    apiRequest({
      endpoint: `/api/getuserbookings`,
      method: "GET",
      thenHandle: (res) => {
        this.bookings = res.data;
      },
      catchHandle: (error) => {
        console.error("Failed to fetch bookings:", error);
      },
    });
  },
};
</script>
<style lang="css" scoped>
#container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 800px) {
  #main_card {
    min-width: 50%;
  }
}
@media screen and (max-width: 800px) {
  #main_card {
    min-width: 100%;
  }
}
</style>
