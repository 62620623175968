<template>
  <v-card>
    <v-card-text>
      <v-text-field
        v-model="search"
        label="Search"
        style="margin-bottom: 10px"
        outlined
        clearable
        hide-details
      >
      </v-text-field>
      <v-select
        outlined
        :items="possibleStatuses"
        label="Status"
        v-model="status"
        hide-details
        style="margin-bottom: 10px"
        clearable
      ></v-select>
      <v-select
        outlined
        :items="['Newest', 'Oldest']"
        label="Sort by"
        v-model="sort"
        hide-details
        style="margin-bottom: 10px"
      ></v-select>
      <v-combobox
        outlined
        :items="users"
        item-text="username"
        item-value="id"
        label="User"
        auto-select-first
        v-model="user"
        hide-details
        clearable
        :loading="usersLoading"
        style="margin-bottom: 10px"
        @focus="getUsers"
      ></v-combobox>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <span v-if="bookings.length === 0 && !loading">No bookings</span>
      <BookingDialog
        v-for="booking in bookings"
        :key="booking.id"
        :bookingProp="booking"
        :message="message"
        :errorMessage="errorMessage"
        :getBookings="getBookings"
        :getTrips="getTrips"
        :getBookingsList="getBookingsList"
      />
      <v-row v-if="bookings.length > 0" align="center">
        <v-col class="shrink text-left" cols="4">
          <v-btn
            v-if="page > 0"
            @click="changePage(false)"
            outlined
            color="primary"
            >Prev</v-btn
          >
        </v-col>
        <v-col class="text-center" cols="4"
          >Page {{ page + 1 }} of {{ Math.ceil(count / pageLimit) }}</v-col
        >
        <v-col class="shrink text-right" cols="4">
          <v-btn
            v-if="count > (page + 1) * pageLimit"
            @click="changePage(true)"
            outlined
            color="primary"
            >Next</v-btn
          >
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import BookingDialog from "./BookingDialog.vue";
export default {
  name: "Bookings",
  components: {
    BookingDialog,
  },
  props: {
    message: Function,
    errorMessage: Function,
    getImageUrl: Function,
    getBookings: Function,
    getTrips: Function,
  },
  data: () => ({
    possibleStatuses: [
      "Pending Cash Option",
      "Pending Verification",
      "Pending",
      "Paid",
      "Allowed",
      "Cancelled",
    ],
    user: "",
    users: [],
    usersLoading: false,
    status: "",
    search: "",
    searchTimeout: null,
    sort: "Newest",
    loading: false,
    page: 0,
    pageLimit: 5,
    bookings: [],
    count: null,
  }),
  computed: {
    statusAndSort() {
      return `${this.status}-${this.sort}`;
    },
  },
  methods: {
    getUsers() {
      this.usersLoading = true;
      axios({
        url: `${
          process.env.VUE_APP_BASE_URL ?? ""
        }/api/getusers?pageLimit=-1&page=0`,
        method: "GET",
        headers: { Authorization: localStorage.getItem("Authorization") },
      })
        .then((resp) => {
          this.users = resp.data.users;
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {
          this.usersLoading = false;
        });
    },
    changePage(next) {
      if (next) this.page++;
      else this.page--;
      this.getBookingsList();
    },
    getBookingsList() {
      this.loading = true;
      const query = `?page=${this.page}&pageLimit=${this.pageLimit}&sort=${
        this.sort
      }&user=${this.user ? this.user.id : ""}&status=${
        this.status ? this.status : ""
      }&search=${this.search ? this.search : ""}`;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/getbookings${query}`,
        method: "GET",
        headers: { Authorization: localStorage.getItem("Authorization") },
      })
        .then((resp) => {
          this.bookings = [];
          this.bookings = resp.data.bookings;
          this.count = resp.data.count;
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getBookingsList();
    const queryStatus = this.$route.query.status;
    if (this.possibleStatuses.includes(queryStatus)) {
      this.status = queryStatus;
    }
  },
  watch: {
    user: {
      handler: function () {
        this.page = 0;
        this.getBookingsList();
      },
    },
    statusAndSort: {
      handler: function () {
        this.page = 0;
        this.getBookingsList();
      },
    },
    search: {
      handler: function () {
        if (this.searchTimeout) clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          this.page = 0;
          this.getBookingsList();
        }, 250);
      },
    },
  },
};
</script>
