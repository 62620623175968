<template>
  <v-app-bar
    v-if="getShouldShowNav()"
    elevation="0"
    style="background-color: #f0e7e5"
  >
    <a href="/login">
      <v-img
        :src="require(`../assets/${getName()}.png`)"
        contain
        max-height="35px"
        max-width="35px"
        class="shrink"
        style="cursor: pointer"
      />
    </a>
    <v-toolbar-title class="hidden-sm-and-down ml-3">{{
      getTitle()
    }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <div style="display: flex" v-if="this.$store.getters.isLoggedIn">
      <RouteSelector />
      <v-btn class="smallXPadding hidden-sm-and-down" disabled text>{{
        this.$store.getters.getUser.username
      }}</v-btn>
      <v-btn
        class="smallXPadding hidden-sm-and-down"
        text
        @click="$router.push('/bookings')"
        >My Bookings</v-btn
      >
      <v-btn
        small
        class="smallXPadding hidden-md-and-up"
        color="primary"
        text
        @click="$router.push('/bookings')"
        ><v-icon>mdi-book</v-icon></v-btn
      >
      <SendFeedback />
      <v-btn
        small
        class="smallXPadding hidden-md-and-up"
        color="primary"
        text
        @click="logout()"
        ><v-icon>mdi-logout</v-icon></v-btn
      >
      <v-btn class="smallXPadding hidden-sm-and-down" text @click="logout()"
        >Log out</v-btn
      >
    </div>
    <div v-else>
      <v-btn
        class="smallXPadding hidden-sm-and-down"
        text
        @click="$router.push('/login')"
        >Log In</v-btn
      >
      <v-btn
        class="smallXPadding hidden-md-and-up"
        text
        @click="$router.push('/login')"
        ><v-icon>mdi-login</v-icon></v-btn
      >
    </div>
  </v-app-bar>
</template>
<script>
import { configEnabled } from "../helpers";
import RouteSelector from "./Common/RouteSelector.vue";
import SendFeedback from "./SendFeedback.vue";
export default {
  name: "Nav",
  data: () => ({}),
  methods: {
    getShouldShowNav() {
      const store = this.$store.getters;
      const isLoggedIn = store.isLoggedIn;
      const hasRole =
        isLoggedIn &&
        (store.getUser.isAdmin ||
          store.getUser.isCaptain ||
          store.getUser.isSuperUser);
      const allowCustomerLogin = configEnabled(
        "Enable customer login",
        this.$store.getters.getConfigs
      );
      return allowCustomerLogin || hasRole;
    },
    getName() {
      return process.env.VUE_APP_NAME ?? "bmt";
    },
    getTitle() {
      const title = process.env.VUE_APP_TITLE ?? "Book My Transfer";
      return title.toUpperCase();
    },
    logout() {
      this.$store.dispatch("logout");
      if (this.$route.name !== "Home") {
        this.$router.push("/login");
      } else {
        this.$router.go(0);
      }
    },
  },
  components: { SendFeedback, RouteSelector },
};
</script>

<style scoped>
.smallXPadding {
  padding-left: 8px !important;
  padding-right: 8px !important;
}
</style>
