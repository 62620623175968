<template>
  <div>
    <v-snackbar v-model="snackbar" top color="green">{{
      snackbarText
    }}</v-snackbar>
    <v-snackbar v-model="errorSnackbar" top color="red" outlined>{{
      errorSnackbarText
    }}</v-snackbar>
    <div>
      <Nav />
      <v-container>
        <v-row justify="center">
          <v-col v-if="selectedTrip" xs="12" sm="12" md="8" lg="6" xl="5">
            <v-card-title
              class="pa-0 mb-2"
              @click="
                selectedTrip = null;
                bookings = [];
              "
            >
              <v-btn outlined color="primary">
                <v-icon class="mr-2">mdi-arrow-left-bold-circle-outline</v-icon
                >Back
              </v-btn>
            </v-card-title>
            <v-card>
              <v-card-text v-if="!selectedTrip.departed">
                <v-btn
                  color="primary"
                  outlined
                  @click="depart(true)"
                  :loading="departLoading"
                  block
                  large
                  >Depart
                </v-btn>
              </v-card-text>
              <v-card-text v-if="selectedTrip.departed && !selectedTrip.ended">
                <v-btn
                  color="primary"
                  outlined
                  @click="end(true)"
                  block
                  large
                  :loading="endLoading"
                  >End Trip</v-btn
                >
              </v-card-text>
              <v-card-title class="mb-2" style="justify-content: space-between">
                <span>{{ toFromString(selectedTrip) }}</span>
              </v-card-title>
              <v-card-subtitle>
                <div>
                  <v-icon class="mb-1">mdi-ferry</v-icon>
                  <span class="subtitle-1">{{ selectedTrip.boat.name }}</span>
                </div>
                <v-icon dense class="mb-1">mdi-map-marker</v-icon>
                <span class="subtitle-1">{{
                  selectedTrip.fromLocation.name
                }}</span>
                <v-icon class="mb-1">mdi-ray-start-arrow</v-icon>
                <span class="subtitle-1">{{
                  selectedTrip.toLocation.name
                }}</span>
                <div style="display: flex">
                  <div>
                    <div v-for="point in pickupPoints">
                      {{ point.count }} from {{ point.name }}
                    </div>
                  </div>
                  <div style="margin-left: 1rem">
                    <div v-for="point in dropoffPoints">
                      {{ point.count }} to {{ point.name }}
                    </div>
                  </div>
                </div>
                <div>
                  <v-icon dense>mdi-account</v-icon>
                  {{ selectedTrip.booked }}/{{ selectedTrip.capacity }} seats
                  booked
                  <SeatBreakdown :bookings="selectedTrip.bookings" />
                </div>
                <div>
                  <FuelConsumption
                    :trip="selectedTrip"
                    :message="message"
                    :errorMessage="errorMessage"
                    :getTrips="getTrips"
                  />
                </div>
                <div>
                  <TripRemarks
                    :trip="selectedTrip"
                    :message="message"
                    :errorMessage="errorMessage"
                    :getTrips="getTrips"
                  />
                </div>
                <div
                  v-if="selectedTrip.departed && !selectedTrip.ended"
                  style="margin-top: 10px"
                >
                  <v-chip class="ma-2" color="blue"
                    >Departed at
                    {{ formatTime(selectedTrip.departedAt) }}</v-chip
                  >
                  <v-btn
                    color="red"
                    outlined
                    small
                    @click="depart(false)"
                    :loading="departLoading"
                    >Cancel depart
                  </v-btn>
                </div>
                <div
                  v-if="selectedTrip.departed && selectedTrip.ended"
                  style="margin-top: 10px"
                >
                  <v-chip class="ma-2" color="green"
                    >Ended at {{ formatTime(selectedTrip.endedAt) }}</v-chip
                  >
                  <v-btn
                    color="red"
                    outlined
                    small
                    @click="end(false)"
                    :loading="endLoading"
                    >Cancel end</v-btn
                  >
                </div>
              </v-card-subtitle>
              <v-card-text>
                <v-progress-linear
                  v-if="bookingsLoading"
                  indeterminate
                  color="primary"
                ></v-progress-linear>
                <span v-if="bookings.length === 0 && !bookingsLoading"
                  >No bookings</span
                >
                <v-text-field
                  style="margin-bottom: 0.5rem"
                  v-model="bookingSearch"
                  placeholder="Search bookings"
                  outlined
                  hide-details
                  dense
                  clearable
                ></v-text-field>
                <div
                  v-for="booking in filterBookings(bookings)"
                  :key="booking.id"
                  style="background-color: rgb(250 250 250)"
                >
                  <BookingDialog
                    :bookingProp="booking"
                    :message="message"
                    :errorMessage="errorMessage"
                    :getBookings="getBookings"
                    :getTrips="getTrips"
                    :hideCustomers="true"
                    :noBottomSpacing="true"
                    :seatConfig="configEnabled('Enable seat selection feature')"
                  />
                  <div
                    v-for="customer in booking.customers"
                    :key="customer.id"
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      padding-left: 1rem;
                      padding-right: 1rem;
                      margin-bottom: 1rem;
                    "
                  >
                    <span>
                      <span class="subtitle-1">{{ customer.name }}</span>
                    </span>
                    <span>
                      <v-checkbox
                        v-if="selectedTrip.id === booking.tripId"
                        :disabled="
                          selectedTrip.departed ||
                          selectedTrip.ended ||
                          (!booking.isPaid && !booking.isAllowed)
                        "
                        class="customerCheckbox"
                        v-model="customer.hasBoarded"
                        @change="
                          markAttendance(
                            selectedTrip,
                            booking,
                            customer,
                            customer.hasBoarded
                          )
                        "
                      ></v-checkbox>
                      <v-checkbox
                        v-if="selectedTrip.id === booking.returnTripId"
                        :disabled="
                          selectedTrip.departed ||
                          selectedTrip.ended ||
                          (!booking.isPaid && !booking.isAllowed)
                        "
                        class="customerCheckbox"
                        v-model="customer.hasBoardedReturn"
                        @change="
                          markAttendance(
                            selectedTrip,
                            booking,
                            customer,
                            customer.hasBoardedReturn
                          )
                        "
                      >
                      </v-checkbox>
                    </span>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col v-else xs="12" sm="12" md="8" lg="6" xl="5">
            <v-card-title class="pa-0 mb-2">
              <v-btn text color="black" style="pointer-events: none"
                >Trips</v-btn
              >
            </v-card-title>
            <v-card>
              <v-card-text>
                <v-dialog
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      :value="computedDate"
                      label="Date"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    @input="menu = false"
                  ></v-date-picker>
                </v-dialog>
                <v-progress-linear
                  v-if="tripsLoading"
                  indeterminate
                  color="primary"
                ></v-progress-linear>
                <div v-if="trips.length === 0">No trips for the day.</div>
                <v-card
                  flat
                  color="grey lighten-4"
                  class="mb-2"
                  ripple
                  v-for="trip in trips"
                  :key="trip.id"
                  @click="selectedTrip = trip"
                >
                  <v-card-text class="pt-4">
                    <v-row align="center">
                      <v-col style="padding-right: 0px">
                        <span class="title">{{ toFromString(trip) }}</span>
                      </v-col>
                      <v-col class="shrink" style="padding: 0px">
                        <v-chip
                          v-if="trip.departed && !trip.ended"
                          class="ma-2"
                          color="blue"
                          >Departed</v-chip
                        >
                        <v-chip
                          v-if="trip.departed && trip.ended"
                          class="ma-2"
                          color="green"
                          >Ended</v-chip
                        >
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col style="padding-top: 0px">
                        <div>
                          <v-icon class="mb-1">mdi-ferry</v-icon>
                          <span class="subtitle-1">{{ trip.boat.name }}</span>
                        </div>
                        <v-icon dense class="mb-1">mdi-map-marker</v-icon>
                        <span class="subtitle-1">{{
                          trip.fromLocation.name
                        }}</span>
                        <v-icon class="mb-1">mdi-ray-start-arrow</v-icon>
                        <span class="subtitle-1">{{
                          trip.toLocation.name
                        }}</span>
                      </v-col>
                      <v-col class="shrink" style="padding-top: 0px">
                        <v-btn text disabled>
                          <v-icon dense>mdi-account</v-icon>
                          {{ trip.booked }}/{{ trip.capacity }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import SeatBreakdown from "../components/Booking/SeatBreakdown.vue";
import BookingDialog from "../components/BookingDialog.vue";
import ConfirmCash from "../components/ConfirmCash.vue";
import Nav from "../components/Nav.vue";
import FuelConsumption from "../components/Trip/FuelConsumption.vue";
import TripRemarks from "../components/Trip/TripRemarks.vue";
import {
  DT_FORMATS,
  apiRequest,
  configEnabled,
  toFromString,
} from "../helpers";

export default {
  name: "Captain",
  components: {
    Nav,
    ConfirmCash,
    BookingDialog,
    FuelConsumption,
    TripRemarks,
    SeatBreakdown,
  },
  data: () => ({
    snackbar: false,
    snackbarText: "",
    errorSnackbar: false,
    errorSnackbarText: "",
    menu: null,
    date: moment().format("YYYY-MM-DD"),
    tripsLoading: false,
    trips: [],
    selectedTrip: null,
    bookings: [],
    bookingsLoading: false,
    departLoading: false,
    endLoading: false,
    configs: [],
    bookingSearch: "",
  }),
  computed: {
    bookingsSorted() {
      if (!this.bookings) return [];
      return this.bookings.sort(
        (a, b) =>
          Number(this.bookingAllBoarded(a)) - Number(this.bookingAllBoarded(b))
      );
    },
    computedDate() {
      return this.date ? moment(this.date).format("dddd, D MMMM YYYY") : "";
    },
    pickupPoints() {
      if (!this.bookings || this.bookings.length === 0) return [];
      const allPoints = [];
      for (const booking of this.bookings) {
        if (!booking.isPaid && !booking.isAllowed && !booking.isCashAllowed)
          continue;
        let point = null;
        if (booking.tripId !== this.selectedTrip.id && booking.dropoffPoint) {
          point = booking.dropoffPoint;
        } else if (booking.pickupPoint) {
          point = booking.pickupPoint;
        }
        if (point) {
          point.count = this.occupiesSeatCount(booking);
          allPoints.push(point);
        }
      }
      return this.uniquePoints(allPoints);
    },
    dropoffPoints() {
      if (!this.bookings || this.bookings.length === 0) return [];
      const allPoints = [];
      for (const booking of this.bookings) {
        if (!booking.isPaid && !booking.isAllowed && !booking.isCashAllowed)
          continue;
        let point = null;
        if (booking.tripId !== this.selectedTrip.id && booking.pickupPoint) {
          point = booking.pickupPoint;
        } else if (booking.dropoffPoint) {
          point = booking.dropoffPoint;
        }
        if (point) {
          point.count = this.occupiesSeatCount(booking);
          allPoints.push(point);
        }
      }
      return this.uniquePoints(allPoints);
    },
  },
  methods: {
    bookingAllBoarded(booking) {
      if (this.isReturnTrip(booking)) {
        return booking.customers.every((c) => c.hasBoardedReturn);
      } else {
        return booking.customers.every((c) => c.hasBoarded);
      }
    },
    filterBookings(bookings) {
      let filtered = this.bookingsSorted;
      if (this.bookingSearch) {
        const lower = this.bookingSearch.toLocaleLowerCase();
        filtered = filtered.filter(
          (b) =>
            b.refNo?.includes(lower) ||
            b.phone?.includes(lower) ||
            b.email?.toLocaleLowerCase().includes(lower)
        );
      }
      return filtered;
    },
    configEnabled(name) {
      const found = this.configs.find((c) => c.name === name);
      if (found?.value === "true") return true;
      return false;
    },
    getConfigs() {
      const endpoint = "/api/getconfigs";
      const thenHandle = (resp) => {
        const configs = resp?.data?.configs;
        if (configs) {
          this.configs = configs;
          this.$store.dispatch("configure", {
            configs,
          });
        }
      };
      apiRequest({
        endpoint,
        method: "GET",
        thenHandle,
      });
    },
    uniquePoints(points) {
      const uniquePoints = [];
      for (const point of points) {
        const uniquePointIds = uniquePoints.map((p) => p.id);
        if (uniquePointIds.includes(point.id)) {
          const current = uniquePoints.find((p) => p.id === point.id);
          current.count += point.count;
        } else {
          uniquePoints.push(point);
        }
      }
      return uniquePoints;
    },
    occupiesSeatCount(booking) {
      let count = 0;
      for (const customer of booking.customers) {
        if (customer.ageGroup.occupiesSeat) count++;
      }
      return count;
    },
    isReturnTrip(booking) {
      if (booking.tripId === this.selectedTrip.id) return false;
      return true;
    },
    pickupPoint(booking) {
      if (!booking) return null;
      if (!booking.pickupPoint && !booking.dropoffPoint) return null;
      if (this.isReturnTrip(booking)) {
        return booking.dropoffPoint ?? null;
      } else {
        return booking.pickupPoint ?? null;
      }
    },
    dropoffPoint(booking) {
      if (!booking) return null;
      if (!booking.pickupPoint && !booking.dropoffPoint) return null;
      if (this.isReturnTrip(booking)) {
        return booking.pickupPoint ?? null;
      } else {
        return booking.dropoffPoint ?? null;
      }
    },
    message(message) {
      this.snackbarText = message;
      this.snackbar = true;
    },
    errorMessage(message) {
      this.errorSnackbarText = message;
      this.errorSnackbar = true;
      if (message === "Token expired.") {
        this.$store.dispatch("logout");
      }
    },
    toFromString(trip) {
      return toFromString(trip, this.$store.getters.getConfigs);
    },
    formatTime(time) {
      let format = DT_FORMATS.time12hr;
      if (configEnabled("Use 24 hour clock", this.$store.getters.getConfigs)) {
        format = DT_FORMATS.time24hr;
      }
      return moment(time).format(format);
    },
    getTrips() {
      this.tripsLoading = true;
      const date = moment(this.date).startOf("day").toISOString();
      axios({
        url: `${
          process.env.VUE_APP_BASE_URL ?? ""
        }/api/getcaptaintrips?date=${date}`,
        method: "GET",
        headers: { Authorization: localStorage.getItem("Authorization") },
      })
        .then((resp) => {
          const trips = resp.data.trips ?? [];
          this.trips = trips;
          if (this.selectedTrip) {
            trips.forEach((tr) => {
              if (tr.id === this.selectedTrip.id) {
                this.selectedTrip = tr;
              }
            });
          } else if (this.$route.query.trip) {
            trips.forEach((tr) => {
              if (tr.id === parseInt(this.$route.query.trip)) {
                this.selectedTrip = tr;
              }
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {
          this.tripsLoading = false;
        });
    },
    getBookings() {
      if (this.selectedTrip === null) return;
      this.bookingsLoading = true;
      axios({
        url: `${
          process.env.VUE_APP_BASE_URL ?? ""
        }/api/getbookingsfortrip?tripId=${this.selectedTrip.id}&captain=true`,
        method: "GET",
        headers: { Authorization: localStorage.getItem("Authorization") },
      })
        .then((resp) => {
          const bookings = resp.data.bookings ?? [];
          bookings.forEach((booking) =>
            booking.customers.sort((a, b) => a.id - b.id)
          );
          this.bookings = bookings;
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {
          this.bookingsLoading = false;
        });
    },
    markAttendance(trip, booking, customer, boarded) {
      const data = {
        tripId: trip.id,
        bookingId: booking.id,
        wholeBooking: false,
        customerId: customer.id,
        boarded,
      };
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/markattendance`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => this.getBookings());
    },
    depart(trueOrFalse) {
      if (!this.selectedTrip) return;
      this.departLoading = true;
      const data = {
        tripId: this.selectedTrip.id,
        departed: trueOrFalse,
      };
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/depart`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message);
          this.getTrips();
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => (this.departLoading = false));
    },
    end(trueOrFalse) {
      if (!this.selectedTrip) return;
      this.endLoading = true;
      const data = {
        tripId: this.selectedTrip.id,
        ended: trueOrFalse,
      };
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/end`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message);
          this.getTrips();
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => (this.endLoading = false));
    },
    dateFromQuery() {
      if (this.$route.query.date) {
        const date = moment(this.$route.query.date);
        if (date.isValid()) {
          const formattedDate = date.format("YYYY-MM-DD");
          if (this.date !== formattedDate) {
            this.date = formattedDate;
            return true;
          }
        }
      }
      return false;
    },
  },
  created() {
    const user = this.$store.getters.getUser;
    if (!user || !user.isCaptain) {
      this.errorMessage("Need captain rights to access '/captain' page.");
      this.$router.push("/login");
      return;
    }
    this.getConfigs();
    this.currentUser = user;
    this.getTrips();
  },
  watch: {
    date: {
      handler: function () {
        this.getTrips();
        if (this.$route.query.date !== this.date) {
          this.$router.push({
            query: {
              date: this.date,
            },
          });
        }
      },
    },
    selectedTrip: {
      handler: function () {
        if (this.selectedTrip) {
          this.getBookings();
          if (this.selectedTrip.id !== parseInt(this.$route.query.trip)) {
            this.$router.push({
              query: {
                ...this.$route.query,
                trip: this.selectedTrip.id,
              },
            });
          }
        } else {
          this.$router.push({
            query: { date: this.$route.query.date },
          });
        }
      },
    },
  },
};
</script>

<style>
.customerCheckbox .v-icon {
  font-size: 50px !important;
}
</style>
